import React, { useEffect, useMemo } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react'
import { clusterApiUrl } from '@solana/web3.js'
import {
  // LedgerWalletAdapter,
  PhantomWalletAdapter,
  // SlopeWalletAdapter,
  // SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  // TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets'
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui'

import './styles/app.scss'

import RouteURL from '~routes'
import OverlayStateProvider from '~providers/OverlayStateProvider'
import AssetHub from '~containers/AssetHub'
import MediaPage from '~containers/MediaPage'

import Layout from './components/Layout'

import '@solana/wallet-adapter-react-ui/styles.css'

const App = () => {
  const { pathname } = useLocation()

  const network = WalletAdapterNetwork.Devnet
  const endpoint = useMemo(() => clusterApiUrl(network), [network])

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      // new SlopeWalletAdapter(),
      // new SolflareWalletAdapter(),
      // new TorusWalletAdapter(),
      // new LedgerWalletAdapter(),
      new SolletWalletAdapter({ network }),
      new SolletExtensionWalletAdapter({ network }),
    ],
    [network]
  )

  // Restore scroll on location change
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <OverlayStateProvider>
            <Layout>
              <Routes>
                {/* The `/*` route, so has nomatch navigate inside */}
                <Route path={RouteURL.MEDIA} element={<MediaPage />} />
                <Route path={`${RouteURL.ASSET_HUB}*`} element={<AssetHub />} />
              </Routes>
            </Layout>
          </OverlayStateProvider>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  )
}

export default App
