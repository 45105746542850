import RouteURL from '~routes'

export const TOP_NAV_ITEMS = [
  {
    text: 'ASSET HUB',
    subItems: [
      {
        text: 'TOKEN STAKING',
        href: RouteURL.ASSET_HUB,
        iconName: 'icon-nav-blockasset',
      },
      {
        text: 'NFT STAKING',
        href: RouteURL.NFT_STAKING,
        iconName: 'icon-nav-nft',
      },
      {
        text: 'NFT STAKED',
        href: RouteURL.NFT_STAKED,
        requiresLogIn: true,
        iconName: 'icon-nav-nft-staked',
      },
    ],
  },
  {
    text: 'SWAP',
    subItems: [
      { text: 'TRADE', href: '/trade' },
      { text: 'LIQUIDITY', href: '/liquidity' },
      { text: 'LAUNCHPAD', href: '/launchpad' },
    ],
  },
  { text: 'MARKETCAP', href: '/marketcap' },
  { text: 'NFTs', href: '/nft-marketplace' },
  { text: 'MEDIA', href: RouteURL.MEDIA },
]
