import React from 'react'
import { Container } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'

import useAuth from '~hooks/useAuth'
import BlockassetLogo from '~components/BlockassetLogo'

import HeaderNavMenu from './components/HeaderNavMenu'
import HeaderNavMobile from './components/HeaderNavMobile'
import DropdownWallet from './components/DropdownWallet'
import ButtonConnect from './components/ButtonConnect'
import * as style from './Header.module.scss'

const Header = () => {
  const { isLoggedIn } = useAuth()

  const isUpMd = useMediaQuery({
    query: '(min-width: 640px)',
  })

  const isDLg = useMediaQuery({
    query: '(max-width: 1023.99px)',
  })

  return (
    <header className={style.headerWrapper}>
      <Container className={style.header}>
        <BlockassetLogo variant="header" />
        <HeaderNavMenu className={style.navMenu} />
        <div className={style.gridHeaderRight}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isUpMd ? isLoggedIn ? <DropdownWallet /> : <ButtonConnect /> : null}
          {isDLg && <HeaderNavMobile />}
        </div>
      </Container>
    </header>
  )
}

export default Header
