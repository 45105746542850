import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from 'react-bootstrap'

import Icon from '~components/Icon'
import OverlayContext from '~contexts/OverlayContext'

import BlockTokenPrice from '../BlockTokenPrice'
import * as style from './ButtonConnect.module.scss'

const ButtonConnect = (props) => {
  const { className, onClick, ...rest } = props

  const { showModalConnectWallet } = useContext(OverlayContext)

  return (
    <>
      <Button
        {...rest}
        className={classNames(style.buttonConnect, className)}
        variant="secondary"
        onClick={() => {
          showModalConnectWallet()
          onClick()
        }}
      >
        <div className={style.blockIcon}>
          <Icon name="icon-user" size={24} />
        </div>
        <BlockTokenPrice />
      </Button>
      <svg aria-hidden="true" focusable="false" className="svg-defs-hidden">
        <linearGradient id="user-gradient" x2="1" y2="1">
          <stop offset="0%" stopColor="#4B98CD" />
          <stop offset="100%" stopColor="#1FCFB1" />
        </linearGradient>
      </svg>
    </>
  )
}

ButtonConnect.defaultProps = {
  className: '',
  onClick: () => {},
}

ButtonConnect.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default ButtonConnect
