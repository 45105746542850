import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

import useAuth from '~hooks/useAuth'
import { TOP_NAV_ITEMS } from '~utils/constants'
import Icon from '~components/Icon'

import * as style from './SubNavMenu.module.scss'

const SubNavMenu = (props) => {
  const { className, ...rest } = props

  const { isLoggedIn } = useAuth()

  const { subItems } = TOP_NAV_ITEMS[0]

  return (
    <div {...rest} className={classNames(style.subNavMenu, className)}>
      <svg
        className="svg-defs-hidden"
        aria-hidden="true"
        focusable="false"
        tabIndex={-1}
      >
        <defs>
          <radialGradient
            id="radialRainbow"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(-15.76635 14.00344 -9.49899 -10.69483 17.006 4.258)"
          >
            <stop stopColor="#F196D1" />
            <stop offset=".237" stopColor="#CF97EC" />
            <stop offset=".477" stopColor="#99BDED" />
            <stop offset=".67" stopColor="#9CECCA" />
            <stop offset=".826" stopColor="#FFDDB4" />
            <stop offset="1" stopColor="#F896C5" />
          </radialGradient>
        </defs>
      </svg>
      <Nav variant="gradient" activeKey="">
        {subItems.map(({ text, href, requiresLogIn, iconName }) => (
          <Nav.Link
            key={text}
            as={NavLink}
            to={href}
            eventKey={href}
            disabled={requiresLogIn && !isLoggedIn}
          >
            <Icon name={iconName} size={20} />
            <span>{text}</span>
          </Nav.Link>
        ))}
      </Nav>
    </div>
  )
}

SubNavMenu.defaultProps = {
  className: '',
}

SubNavMenu.propTypes = {
  className: PropTypes.string,
}

export default SubNavMenu
